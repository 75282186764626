.slider-container {
    display: flex;
    align-items: center;
  }
  
  .slider-input {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #e3e3e3;
    outline: none;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.2s;
  }
  
  .slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #3498db;
    cursor: pointer;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transition: background 0.2s, transform 0.2s;
  }
  
  .slider-input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #3498db;
    cursor: pointer;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transition: background 0.2s, transform 0.2s;
  }
  
  .slider-value {
    margin-left: 10px;
    font-size: 1.2em;
    color: #555;
  }
  