.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%);
}

.ball {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  transition: transform 4s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.1);
  background: radial-gradient(circle at top left, #60c0ff 20%, #4a95c6 90%);
}

.breathe {
  transform: scale(1.3);
}

.breathing-text {
  position: absolute;
  font-family: 'Nunito', sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  pointer-events: none;
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-in {
  opacity: 1;
  transition-delay: 0.5s;
}

.fade-out {
  opacity: 0;
}

.side-menu {
  width: 210px;
  height: 100%;
  position: fixed;
  padding: 20px;
  top: 0;
  color: #5788d3;
  left: -250px;
  padding-top: calc(1.5em + 10px);
  transition: left 0.3s ease;
}

.side-menu.open {
  left: 0;
}

.hamburger {
  cursor: pointer;
  color: rgb(198, 198, 198);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 10px;
  padding: 5px;
  font-size: 1.5em;
  transition: transform 0.5s ease;
}

.hamburger.rotate {
  transform: rotate(180deg);
}

.notransition {
  transition: none !important;
}