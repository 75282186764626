.homepage {
    padding: 10px 20px;
    margin-bottom: 20px;
}
.launchapp {
    background-color: rgb(56, 126, 255);
    color: white;
    border-radius: 25px;
    padding: 10px;
    text-decoration: none;
    font-size: 1.1em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.launchapp:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 10px;
    background-color: rgb(49, 113, 233);
}
h2 {
    margin: 0px 0;
}
h1 {
    margin: 0;
}