.color-palette {
    display: flex;
    gap: 10px;
    border-radius: 5px;
    padding: 5px;
  }
  
  .color-box {
    width: 50px;
    height: 50px;
    padding: 1.5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  .color-box.selected {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    padding: 0px;
    border: 1.5px dashed rgba(0, 0, 0, 0.35)
  
  }